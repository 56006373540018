import styled from "styled-components";

const LogoContainer = styled.div`
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
`;

const TwoLogosContainer = styled.div`
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
`;

const Image = styled.img`
    width: 25%;
`;

const BigImage = styled.img`
    width: 25%;
`;

const ImageLogin = styled.img`
    width: 30%;
`;

const BigImageLogin = styled.img`
    width: 40%;
`;

const PaddedLogoContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    ${(props) => (!props.noPadding ? "padding-top: 40%;" : null)};
    padding-bottom: 1rem;
`;

export const Logo = ({ noMargin }) => {
    return (
        <LogoContainer>
            <Image src={`${process.env.CDN_HOST}/vinco_logo.png`} noMargin={noMargin} />
        </LogoContainer>
    );
};

export const LogoLogin = ({ noMargin, logoCompany }) => {

    if(typeof logoCompany === "undefined" || logoCompany === null || logoCompany === "undefined") {
        return (
            <LogoContainer>
                <BigImageLogin src={`${process.env.CDN_HOST}/vinco_logo.png`} noMargin={noMargin} />
            </LogoContainer>
        );
        } else {
        return (
            <TwoLogosContainer>
                <ImageLogin src={`${logoCompany}`} noMargin={noMargin} />
                <ImageLogin src={`${process.env.CDN_HOST}/vinco_logo.png`} noMargin={noMargin} />
            </TwoLogosContainer>
        );
    }
};

export const HeaderWithoutTitle = ({ noMargin, noPadding }) => {
    return (
        <PaddedLogoContainer noPadding={noPadding}>
            <BigImageLogin src={`${process.env.CDN_HOST}/vinco_logo.png`} noMargin={noMargin} />
        </PaddedLogoContainer>
    );
};

export const HeaderWithoutTitleSingup = ({ noMargin, noPadding }) => {
    return (
        <PaddedLogoContainer noPadding={noPadding}>
            <BigImage src={`${process.env.CDN_HOST}/vinco_logo.png`} noMargin={noMargin} />
        </PaddedLogoContainer>
    );
};

export const LayoutHeaderLogo = ({ width, minWidth, opacity }) => {
    return (
        <LogoContainer>
            <img src={`${process.env.CDN_HOST}/vinco_logo.png`}
                style={{
                    width: width,
                    minWidth: minWidth,
                    transition: 'opacity 200ms ease-in',
                    opacity: opacity ? '1' : '0'
                }} />
        </LogoContainer>
    )
}