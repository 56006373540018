import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearIcon from '@material-ui/icons/Clear';

import { theme as themeConst, size } from "../styles/theme-constants";
import GeneralButton from './GeneralButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    outline: 'none',
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
    [`@media (max-width: ${size.tabletL})`]: {
      width: '50%'
    },
    [`@media (max-width: ${size.mobileL})`]: {
      width: '80%'
    },
    width: '500px',
  },
  iconContainer: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    cursor: 'pointer'
  },
  purpleMessage: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '30px',
    marginTop: '2rem',
    textAlign: 'left',
    fontSize: '1.2rem',
    color: 'rgba(47, 1, 135, 1)',
    marginBottom: '1rem',
    [`@media (max-width: ${size.mobileL})`]: {
      fontSize: '1rem',
      lineHeight: '1rem',
    },
  },
  followProcess: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '27px',
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: '2rem',
    [`@media (max-width: ${size.mobileL})`]: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
    },
  },
  imageContainer: {
    marginBottom: '2.5rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  lockImage: {
    width: '100px'
  },
  changePassword: {
    lineHeight: '30px',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: '1rem',
    [`@media (max-width: ${size.mobileL})`]: {
      fontSize: '1rem',
      lineHeight: '1rem',
    },
  },
  mustContaine: {
    lineHeight: '21px',
    marginTop: '-3px',
    textAlign: 'left',
    fontStyle: 'normal',
    fontSize: '0.9rem',
    color: themeConst.gray,
    marginBottom: '1.5rem',
  },
  generalButtonContainer: {
  },
  yourEmail: {
    marginTop: '1.5rem',
    borderLeft: `5px solid ${themeConst.secondary}`,
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column'
  },
  yourEmailsubtitle: {
    fontSize: '14px'
  }
}));

export default function LoginModal({ 
  open, 
  closeModal,
  username 
}) {
  const router = useRouter();
  const classes = useStyles();

  const handleClose = () => {
    closeModal();
  };

  const validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  const isEmailValid = validEmail.test(username);

  const redirectToResetPassword = () => {
    closeModal();
    const shortCircuitRoute = {
      pathname: `/forgot-password`,
      query: { email: null }
    }
    let { query, ...result } = shortCircuitRoute;
    router.push({
      ...result,
      ...(isEmailValid && { query: `email=${username}` })
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.iconContainer} onClick={() => handleClose()}>
            <ClearIcon />
          </div>
          <div className={classes.purpleMessage}>
            <span>Vinco ha actualizado sus medidas de seguridad </span>
          </div>
          <div className={classes.followProcess}>
            <span>Sigue el proceso para cambiar tu contraseña a una más segura</span>
          </div>
          <div className={classes.imageContainer}>
            <img className={classes.lockImage} src={`${process.env.CDN_HOST}/password_renew.png`} />
          </div>
          <div className={classes.changePassword}>
            <span>Cambia tu contraseña (~3 min.)</span>
          </div>
          <div className={classes.mustContaine}>
            <span>Debe incluir al menos 6 caracteres, una mayúscula y un número.</span>
          </div>
          <div className={classes.generalButtonContainer}>
            <GeneralButton
              button={{
                text: 'Cambiar Contraseña',
                type: "go",
                func: () => redirectToResetPassword()
              }}
              width={'100%'} />
          </div>
          {username && isEmailValid && <div className={classes.yourEmail}>
            <span className={classes.yourEmailTitle}>TU CORREO:</span>
            <span className={classes.yourEmailsubtitle}>{username}</span>
          </div>}
        </div>
      </Fade>
    </Modal>
  );
}