import * as yup from 'yup';

const companyShape = {
  id: yup.number().required(),
  comp_name: yup.string().required()
};

export const company = yup
  .object()
  .shape(companyShape)
  .nullable(true)
  .required('Escribe el nombre de tu empresa u organización aquí');

export const companyString = yup
  .string()
  .required('Pertenecer a una compañía es requerido');

export const employeeFamilies = yup
  .string()
  .required('El tipo de usuario es un campo requerido');
  
export const internalCompanyId = yup
  .string()
  .required('Este campo es requerido para continuar');

export const valPassword = yup
  .string()
  .required('Este campo es requerido para continuar')
  .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]|.*\W).*$/, 'La contraseña debe contener una mayúscula, un número o un carácter especial')
  .min(6, 'La contraseña debe contener por lo menos 6 caracteres');

export const valIsTermsAccepted = yup
  .bool()
  .oneOf([true], 'Es necesario aceptar los terminos y condiciones')

export const firstName = yup
  .string()
  .required('Este campo es requerido para continuar')
  .min(2, 'Este campo debe contener como mínimo dos letras')
  .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, 'Este campo solo puede contener letras');

export const paternalLastname = yup
  .string()
  .required('Este campo es requerido para continuar')
  .min(2, 'Este campo debe contener como mínimo dos letras')
  .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, 'Este campo solo puede contener letras');

export const maternalLastname = yup
  .string()
  .min(2, 'Este campo debe contener como mínimo dos letras')
  .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, 'Este campo solo puede contener letras');

export const callingCountryCode = ({ isRequired }) => {
  return isRequired ? yup.string().required('Este campo es requerido') : yup.string();
}

export const secondaryCallingCountryCode = ({ isRequired }) => {
  return isRequired ? yup.string().required('Este campo es requerido') : yup.string();
}

export const gender = ({ isRequired}) => {
  return isRequired ?
    yup
      .string()
      .required('Favor de seleccionar una opción') :
    yup
      .string();
}

export const date_of_birth = ({ isRequired }) => {
  const minDate = new Date(1900, 0, 1)
  const maxDate = new Date()
  return  isRequired ?
   yup
  .date()
  .min(minDate, `El año debe ser mayor`)
  .max(maxDate, `Elige la fecha correcta`)
  .required('Este campo es requerido para continuar') :
  yup
  .date()
  .min(minDate, `El año debe ser mayor`)
  .max(maxDate, `Elige la fecha correcta`)
}


export const userPhone = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .required('Este campo es requerido')
      .min(8, 'Dependiendo en tu región, este campo debe de contener al menos 8 números')
      .max(14, 'Este campo debe contener máximo 14 números')
      .matches(/^[0-9]+$/, 'Este campo sólo puede contener numeros') :
    yup
      .string()
      .min(8, 'Dependiendo en tu región, este campo debe de contener al menos 8 números')
      .max(14, 'Este campo debe contener máximo 14 números')
      .matches(/^[0-9]+$/, 'Este campo sólo puede contener numeros');
}

export const secondaryUserPhone = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .required('Este campo es requerido')
      .min(8, 'Dependiendo en tu región, este campo debe de contener al menos 8 números')
      .max(14, 'Este campo debe contener máximo 14 números')
      .matches(/^[0-9]+$/, 'Este campo sólo puede contener numeros')
      .when("phone_number", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().notOneOf(
          [yup.ref("phone_number")],
          "no puedes utilizar el mismo número de teléfono"
        )
      }) 
      :
    yup
      .string()
      .min(8, 'Dependiendo en tu región, este campo debe de contener al menos 8 números')
      .max(14, 'Este campo debe contener máximo 14 números')
      .matches(/^[0-9]+$/, 'Este campo sólo puede contener numeros')

}

export const userId = yup
  .string()
  .required()

export const valEmail = yup
  .string()
  .required('Este campo es requerido para continuar')
  .matches(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, 'Revisa que tu correo sea válido');

export const valSubject = yup
  .string()
  .required("Este campo es requerido");

export const valDescription = yup
  .string()
  .required("Este campo es requerido");

export const userEducation = yup
  .string()
  .nullable(true)
  .required('El nivel de educación es requerido');

export const userState = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .nullable(true)
      .required('El estado es requerido') :
    yup
      .string()
      .nullable(true);
}

export const userZip = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .nullable(true)
      .required('El código postal es requerido') :
    yup
      .string()
      .nullable(true);
}

export const userCity = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .nullable(true)
      .required('La ciudad es requerida')
      .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, 'Este campo solo puede contener letras') :
    yup
      .string()
      .nullable(true)
      .matches(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, 'Este campo solo puede contener letras');;
}

export const userStreetAddress = ({ isRequired }) => {
  return isRequired ?
    yup.string().required('Este campo es requerido') :
    yup.string();
}

export const userCountry = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .required("El país es obligatorio") :
    yup.string();
}

// curp validation
const curpRegex = new RegExp(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/);

export const userCurp = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .required('Este campo es requerido') :
      //.matches(curpRegex, "Revise que su clave CURP sea la correcta")
      //.min(18, "La CURP debe contener mínimo 18 caracteres")
      //.max(18, "La CURP debe contener máximo 18 caracteres") :
    yup
      .string()
      //.matches(curpRegex, "Revise que su clave CURP sea la correcta")
      //.min(18, "La CURP debe contener mínimo 18 caracteres")
      //.max(18, "La CURP debe contener máximo 18 caracteres");
}

export const userEnglishLevel = ({ isRequired }) => {
  return isRequired ?
    yup
      .string()
      .required('Favor de seleccionar una opción') :
    yup
      .string();
}

export const programInstitution = yup.string().required("El nombre de la institución es obligatorio");

export const programName = yup.string().required("El nombre del programa es obligatorio");

export const idFileName = ({ isRequired }) => {
  return isRequired ?
    yup.string().required("Favor de adjuntar una identificación oficial") :
    yup.string();
}
