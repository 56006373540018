import { Grid, makeStyles } from "@material-ui/core";
import MarkdownToJSX from "markdown-to-jsx";
import styled from "styled-components";
// import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import { theme as themeStyle } from "../styles/theme-constants";


export const Section = styled(Grid)`
    border-radius: 5px;
    ${(props) => (!props.noPadding ? "padding-bottom: 25px;" : null)};
    margin-bottom: 30px;
`;

 export const SubSection = styled.div`
    width: 100%;
    padding: 10px 0px;
    border-radius: 10px;
    ${(props) =>
        props.bgColor ? "background-color: " + props.bgColor + ";" : null};
`; 



export const useStyles = makeStyles(() => ({
    mainContainer: {
        maxWidth: '100%'
    },
    img: {
        display: 'contents',
        flexDirection: 'column',
        '& img': {
            maxWidth: '100%'
        },
        '& a': {
            display: 'contents',
            color: '#0C7567',
            '&:hover': {
                color: themeStyle.secondary
            },
        },
    },
    reactMarkDown: {
        display: 'contents',
        flexDirection: 'column',
        '& a': {
          cursor: 'pointer',
          color: '#3D56B2',
          fontWeight: 'bold'
       },
        '& a:hover': {
          cursor: 'pointer',
          backgroundColor: '#5AA897',
          color: '#FFF'
       }
      },
      timeLineContainer:{

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap:'1rem'

      },
      timeLineLine:{
        width: '0.1rem',
        height: '3rem',
        background: '#CED7E6',
        marginLeft: '8px',
      },
      timeLineTitleActive:{
        color:'#009888',
        fontSize:'1rem',
        fontWeight:'600'
      } ,
      timeLineCircle:{
        height:'1rem',
        width:'1rem',
        background:'#CED7E6',
        borderRadius:'100%',
        outline:'1px solid #CED7E6',
        outlineOffset: '1px',
        boxShadow: '0px 10px 15px -3px rgba(0,152,136,0.1)',
      },  
      timeLineCircleActive:{
        height:'1rem',
        width:'1rem',
        background:'#009888',
        borderRadius:'100%',
        outline:'1px solid #009888',
        outlineOffset: '1px',
        boxShadow: '0px 10px 15px -3px rgba(0,152,136,0.1)',
      },
      timeLineCircleCanceled:{
        height:'1rem',
        width:'1rem',
        background:'#EF8A7F',
        borderRadius:'100%',
        outline:'1px solid #EF8A7F',
        outlineOffset: '1px',
        boxShadow: '0px 10px 15px -3px rgba(0,152,136,0.1)',
      },
      timeLineTitleCancel:{
        color:'#EF8A7F',
        fontSize:'1rem',
        fontWeight:'600'
      } ,  


}));

export const MarkDownSection = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <MarkdownToJSX 
              className={classes.reactMarkDown}
              options={{
                overrides: {
                  a: {
                    props: {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    }
                  }
                }
              }}
            >
              {props.children}
            </MarkdownToJSX>
        </div>
    );
};
const steps = [
    {
        label: 'SolicitudCreada',
        step: 1,
    },
    {
        label: 'EnProceso',
        step: 2,
    },
    {
        label: 'Pago',
        step: 3,
    },
    {
        label: 'Registrado',
        step: 4,
    },
    {
        label: 'Completado',
        step: 5,
    }
]
export default function AlternateTimeline({application_status}) {

    const [actualStep, setActualStep] = useState(0);

    const classes = useStyles();

    const getStepByApplicationStatus = (application_status) => {
        steps.forEach(({step, label}) => {
            if (label === application_status) setActualStep(step)
        })
    }

    useEffect(() => {
        getStepByApplicationStatus(application_status)

    }, [actualStep])

    return (
        <div style={{display:'flex',flexDirection:'column', marginInline:'',marginBottom:'1.5rem'}}>
            {
                steps.map(({step, label}) => (
                    <>
                        <div className={classes?.timeLineContainer}>
                            <span className={ step <= actualStep ? classes?.timeLineCircleActive : classes?.timeLineCircle}></span>
                            <span className={step === actualStep ? classes?.timeLineTitleActive : ''}>
                                {label === 'SolicitudCreada' ? label = 'Solicitud Creada' : label && label === 'EnProceso' ? label = 'En Proceso' : label}
                            </span>
                        </div> 
                        <div className={classes?.timeLineLine}></div>
                    </>
                ))
            }

            <div className={classes?.timeLineContainer}>
                <span className={(application_status === 'Cancelado' || application_status === 'Baja' || application_status === 'Inactivo'  )? classes?.timeLineCircleCanceled : classes?.timeLineCircle}></span>
                <span className={(application_status === 'Cancelado' || application_status === 'Baja' || application_status === 'Inactivo') ? classes?.timeLineTitleCancel : ''} >Cancelado</span>
            </div>

        </div>
    );
  }
