
import { makeStyles } from "@material-ui/core/styles";
import { theme as themeConst } from '../../styles/theme-constants';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    color: 'white',
    height: '52px',
    fontWeight: '700',
    padding: '0 40px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    fontSize: '18px',
    border:'none',
    cursor:'pointer',
    width: 'inherit',
    boxShadow:'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    
  },
  buttonPrimary: {
    backgroundColor: themeConst.primary,
    '&:hover': {
      backgroundColor: themeConst.white,
      border:`1px solid ${themeConst.primary}`,
      color:themeConst.primary
    },
  },
  disabledButton: {
    backgroundColor: "#D9D8DF",
    color:"#4A4A7C",
    '&:hover': {
      backgroundColor: "#D9D8DF",
      color:"#4A4A7C",
    },
  }
}));



export const Button = ({onClick, IsDisabled = true,label = 'text button',type = 'button'}) => {

  const classes = useStyles();

  return (
   <button className={`${classes.button} ${IsDisabled ? classes.disabledButton : classes.buttonPrimary}`} 
           disabled={IsDisabled} 
           onClick={onClick}
           type={type}
           >
    {label}
   </button>
  )
}
