import React from 'react';
import { theme as themeStyles } from "../../styles/theme-constants";
import { ThemeStyles } from "../themeStyles";
import { TextField, InputAdornment, IconButton, withStyles, Grid, Link} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Button } from "../../vinco-components";
import { useRouter } from 'next/router';

const CssTextField = withStyles({
  root: {
    borderRadius: '5px',
    backgroundColor: '#f5f5f5',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#f5f5f5',
      },
      '&:hover fieldset': {
        borderColor: '#f5f5f5',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

export const LoginForm = ({
  classes,
  error,
  formik,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword
}) => {

  const router = useRouter();

  const onEnterKeyPress = (e) => {
   
  }

  const ForgotPasswordLink = () => (
    <Grid container justifyContent="left">
      <Grid item xs={12}>
        <Link href='/forgot-password' className={ classes.linkButton } >
          ¿Olvidaste tu contraseña? Haz clic aquí
        </Link>
      </Grid>
    </Grid>
  );
 
  return (
    <>
      <form id={'loginform'} className={classes.form} onSubmit={formik.handleSubmit}>
        <ThemeStyles color={themeStyles.secondary}>
          <span className={classes.label}>Correo</span>
          <CssTextField 
            required
            fullWidth
            id="validation-outlined-input"
            name="email"
            autoComplete="@gmail.com"
            variant="outlined"
            placeholder="Correo"
            onChange={formik.handleChange}
            value={formik.values.email}
            type="text"
            onKeyDown={(e) => onEnterKeyPress(e)}
            onBlur={formik.handleBlur}
            className={classes.margin}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <p className={classes.errorline}>{formik.touched.email && formik.errors.email}</p>
          <span className={classes.label}>Contraseña</span>
          <CssTextField
            required
            fullWidth
            id="validation-outlined-input-pass"
            name="password"
            variant="outlined"
            placeholder="Contraseña"
            onChange={formik.handleChange}
            value={formik.values.password}
            type={showPassword ? "text" : "password"}
            onKeyDown={(e) => onEnterKeyPress(e)}
            onBlur={formik.handleBlur}
            className={classes.margin}
            error={formik.touched.password && Boolean(formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                    <span className={classes.showPassword}>
                        {showPassword ? "Ocultar" : "Mostrar"}
                    </span>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className={classes.errorline}>{formik.touched.password && formik.errors.password}</p>
        </ThemeStyles>
        <ForgotPasswordLink />
        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}
        <div style={{width:'100%'}}>
          <Button type={'submit'} IsDisabled={!formik.isValid} label={'Iniciar sesión'}/>
        </div>
      </form>
    </>
  );
};
