import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

export default function Copyright({privacy_policy}) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MuiLink color="inherit" href="https://www.vincoed.com/" target="_blank">
                Vinco Education
            </MuiLink>{' '}
            {new Date().getFullYear()}
            {'. '}
            <br/>
            <MuiLink color="inherit" href={privacy_policy ? privacy_policy : "https://www.vincoed.com/aviso-privacidad.pdf"} target="_blank">
                Aviso de Privacidad
            </MuiLink>{' '}
        </Typography>
    );
}